// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700&family=Poppins:wght@300;400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommonHeading h2 {
  font-size: 3.2rem;
  line-height: 4.8rem;
  font-weight: 600;
}
@media (max-width: 1279px) {
  .CommonHeading h2 {
    font-size: 3rem;
  }
}
@media (max-width: 1199px) {
  .CommonHeading h2 {
    font-size: 2.4rem;
  }
}
@media (max-width: 991px) {
  .CommonHeading h2 {
    font-size: 2.2rem;
  }
}
.CommonHeading p {
  margin-top: 0.8rem;
}
.CommonHeading.SmallHeading h2 {
  font-size: 2.4rem;
}
@media (max-width: 1199px) {
  .CommonHeading.SmallHeading h2 {
    font-size: 2.2rem;
  }
}
@media (max-width: 991px) {
  .CommonHeading.SmallHeading h2 {
    font-size: 2rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/Heading/Heading.scss"],"names":[],"mappings":"AAKI;EACI,iBAAA;EACA,mBAAA;EACA,gBAAA;AAFR;AAIQ;EALJ;IAMQ,eAAA;EADV;AACF;AAGQ;EATJ;IAUQ,iBAAA;EAAV;AACF;AAEQ;EAbJ;IAcQ,iBAAA;EACV;AACF;AAEI;EACI,kBAAA;AAAR;AAIQ;EACI,iBAAA;AAFZ;AAIY;EAHJ;IAIQ,iBAAA;EADd;AACF;AAGY;EAPJ;IAQQ,eAAA;EAAd;AACF","sourcesContent":["@import '../../../Assets/theme/_var.scss';\n\n.CommonHeading {\n    // padding: 0 1.5rem;\n\n    h2 {\n        font-size: 3.2rem;\n        line-height: 4.8rem;\n        font-weight: 600;\n\n        @media (max-width: 1279px) {\n            font-size: 3rem;\n        }\n\n        @media (max-width: 1199px) {\n            font-size: 2.4rem;\n        }\n\n        @media (max-width: 991px) {\n            font-size: 2.2rem;\n        }\n    }\n\n    p {\n        margin-top: 0.8rem;\n    }\n\n    &.SmallHeading {\n        h2 {\n            font-size: 2.4rem;\n\n            @media (max-width: 1199px) {\n                font-size: 2.2rem;\n            }\n\n            @media (max-width: 991px) {\n                font-size: 2rem;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
