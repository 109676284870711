// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error_page {
  display: flex;
  align-items: center;
  min-height: calc(100vh - 78px);
  justify-content: center;
  padding: 0px 20px;
  flex-direction: column;
  text-align: center;
}
.error_page_icon {
  max-width: 14rem;
  max-height: 14rem;
}
.error_page_icon svg {
  width: 100%;
  height: 100%;
}
@media (max-width: 767px) {
  .error_page_icon {
    max-width: 10rem;
    max-height: 10rem;
  }
}
.error_page h2 {
  margin-top: 3rem;
  font-size: 5rem;
  font-weight: 600;
}
@media (max-width: 1439px) {
  .error_page h2 {
    font-size: 4rem;
  }
}
@media (max-width: 767px) {
  .error_page h2 {
    font-size: 3.4rem;
  }
}
.error_page h3 {
  font-size: 2rem;
  font-weight: 600;
  margin: 1rem 0 3rem;
}
@media (max-width: 1439px) {
  .error_page h3 {
    font-size: 1.8rem;
  }
}
@media (max-width: 767px) {
  .error_page h3 {
    font-size: 1.6rem;
  }
}
.error_page a {
  display: block;
  margin-top: 1rem;
  font-size: 2rem;
  transition: 0.3s ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/Components/Pages/ErrorPage/ErrorPage.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,uBAAA;EACA,iBAAA;EACA,sBAAA;EACA,kBAAA;AADJ;AAGI;EACI,gBAAA;EACA,iBAAA;AADR;AAGQ;EACI,WAAA;EACA,YAAA;AADZ;AAIQ;EATJ;IAUQ,gBAAA;IACA,iBAAA;EADV;AACF;AAII;EACI,gBAAA;EACA,eAAA;EACA,gBAAA;AAFR;AAIQ;EALJ;IAMQ,eAAA;EADV;AACF;AAGQ;EATJ;IAUQ,iBAAA;EAAV;AACF;AAGI;EACI,eAAA;EACA,gBAAA;EACA,mBAAA;AADR;AAGQ;EALJ;IAMQ,iBAAA;EAAV;AACF;AAEQ;EATJ;IAUQ,iBAAA;EACV;AACF;AAGI;EACI,cAAA;EACA,gBAAA;EACA,eAAA;EACA,4BAAA;AADR","sourcesContent":["// @import '../../../assets/scss/_var.scss';\n\n.error_page {\n    display: flex;\n    align-items: center;\n    min-height: calc(100vh - 78px);\n    justify-content: center;\n    padding: 0px 20px;\n    flex-direction: column;\n    text-align: center;\n\n    &_icon {\n        max-width: 14rem;\n        max-height: 14rem;\n\n        svg {\n            width: 100%;\n            height: 100%;\n        }\n\n        @media (max-width: 767px) {\n            max-width: 10rem;\n            max-height: 10rem;\n        }\n    }\n\n    h2 {\n        margin-top: 3rem;\n        font-size: 5rem;\n        font-weight: 600;\n\n        @media (max-width: 1439px) {\n            font-size: 4rem;\n        }\n\n        @media (max-width: 767px) {\n            font-size: 3.4rem;\n        }\n    }\n\n    h3 {\n        font-size: 2rem;\n        font-weight: 600;\n        margin: 1rem 0 3rem;\n\n        @media (max-width: 1439px) {\n            font-size: 1.8rem;\n        }\n\n        @media (max-width: 767px) {\n            font-size: 1.6rem;\n        }\n    }\n\n\n    a {\n        display: block;\n        margin-top: 1rem;\n        font-size: 2rem;\n        transition: 0.3s ease-in-out;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
