import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { CloseIcon } from "../../../Assets/Images/Icons/SvgIcons";
import metamaskIcon from "../../../Assets/Images/Icons/wallets/Metamask-no-filled.png";
import walletConnectIcon from "../../../Assets/Images/Icons/wallets/wallet-connect-filled.png";
import "../../Layouts/MainLayout/MainLayout.scss";
import { useDispatch, useSelector } from "react-redux";
import { DAPP_URL_METAMASK, DAPP_URL_TRUSTWALLET } from "../../../constants";
import { browserName, isMobile } from "react-device-detect";
import { Connector, useAccount, useDisconnect, useNetwork } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { getAccount, disconnect } from "@wagmi/core";
import {
  resetUserSlice,
  setIsLoggedIn,
  setNetworkListFrom,
  setNetworkListTo,
  setUserAddressForNotification,
  setUserConnectedChainID,
  setUserConnectedWalletName,
  setUserWalletAddress,
} from "../../../Redux/reducers/user/user";
import toast from "react-hot-toast";
import { formatAddress } from "../../../Services/Helper/helper";

const WalletDropdown = ({ dropdownBtnRef, handleClose }) => {
  const dispatch = useDispatch();
  const selectedNetwork = useSelector(
    (state: any) => state.user.networkFrom.name
  );

  const walletAddress = useSelector(
    (state: any) => state?.user?.userWalletAddress
  );

  const { chain: CONNECTED_CHAIN_ID }: any = useNetwork();
  const { address } = useAccount();
  const { disconnect: resetConfig } = useDisconnect();

  // :: WAGMI ::
  useEffect(() => {
    // console.log("full connector object =>", connector);
    dispatch(setUserConnectedWalletName(Connector?.name));
    dispatch(setUserConnectedChainID(CONNECTED_CHAIN_ID));
    if (address === undefined) {
      walletDisconnect();
    } else if (address) {
      dispatch(setUserWalletAddress(address));
      dispatch(setUserAddressForNotification(address));
      dispatch(setIsLoggedIn(true));
    }
  }, [Connector, address, CONNECTED_CHAIN_ID]);

  useEffect(() => {
    if (localStorage.getItem("addressInternalDisconnect") == null) {
      localStorage.setItem("addressInternalDisconnect", "false");
    }
    if (address) {
      localStorage.setItem("addressInternalDisconnect", "true");
    }
    if (
      localStorage.getItem("addressInternalDisconnect") === "true" &&
      !address
    ) {
      localStorage.setItem("addressInternalDisconnect", "false");
      window.location.reload();
    }
  }, [address]);

  const { open } = useWeb3Modal();
  const isMobileButNotDappBrowser =
    isMobile &&
    browserName?.toLowerCase() !== "chrome webview" &&
    isMobile &&
    browserName?.toLowerCase() !== "webkit";

  const isDesktopOrIsMobileButDappBrowser =
    !isMobile ||
    (isMobile && browserName?.toLowerCase() === "chrome webview") ||
    (isMobile && browserName?.toLowerCase() === "webkit");

  const handleConnect = async (index: any) => {
    try {
      switch (index) {
        case 0:
          await connectWithMetamask();
          break;
        case 1:
          await connectWithTrustWallet();
          break;
        case 2:
          await connectWithPhantom();
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleNetwok = () => {};

  // Metamask connection function
  const connectWithMetamask = async () => {
    try {
      if (isMobile && browserName.toLowerCase() !== "chrome webview") {
        alert("Please ensure your MetaMask is unlocked");
        const metamaskAppDeepLink = `https://metamask.app.link/dapp/${DAPP_URL_METAMASK}`;
        window.open(metamaskAppDeepLink, "_self");
      } else {
        await open();
      }
      handleClose();
    } catch (error) {
      console.error("Metamask wallet error:", error);
    }
  };

  // Connect trust wallet connection function
  const connectWithTrustWallet = async () => {
    try {
      // Check if the device is a mobile device
      if (isMobileButNotDappBrowser) {
        const trustWalletAppDeepLink = `https://link.trustwallet.com/open_url?coin_id=60&url=${encodeURIComponent(
          DAPP_URL_TRUSTWALLET
        )}`;
        window.open(trustWalletAppDeepLink, "_self");
        // }
      } else if (isDesktopOrIsMobileButDappBrowser) {
        await open();
      } else {
      }
      handleClose();
    } catch (error) {
      console.error("TrustWallet error:", error);
    }
  };

  // Phantom wallet connection function
  const connectWithPhantom = async () => {
    try {
      // Check if the device is a mobile device
      if (isMobileButNotDappBrowser) {
        const trustWalletAppDeepLink = `https://link.trustwallet.com/open_url?coin_id=60&url=${encodeURIComponent(
          DAPP_URL_TRUSTWALLET
        )}`;
        window.open(trustWalletAppDeepLink, "_self");
        // }
      } else if (isDesktopOrIsMobileButDappBrowser) {
        await open();
      } else {
      }
      handleClose();
    } catch (error) {
      console.error("Phantom wallet error:", error);
    } finally {
      handleClose();
    }
  };

  // :: WAGMI ::
  const walletDisconnect = async () => {
    try {
      await disconnect();
      toast.success("Wallet Disconnected", { id: "walletDisconnected" });
      dispatch(setIsLoggedIn(false));
      dispatch(setUserWalletAddress(""));
      dispatch(setNetworkListFrom(""));
      dispatch(setNetworkListTo(""));
      // dispatch(resetUserSlice());
      await resetConfig();
    } catch (error) {
      console.error("Error disconnecting wallet:", error);
    }
  };

  return (
    <Dropdown className="wallet_dropdown">
      {walletAddress ? (
        <Dropdown.Toggle
          ref={dropdownBtnRef}
          className="ButtonCustom connect_wallet bordered-green"
          id="wallet-dropdown"
          onClick={walletDisconnect}
        >
          {formatAddress(walletAddress)}
        </Dropdown.Toggle>
      ) : (
        <Dropdown.Toggle
          ref={dropdownBtnRef}
          className="ButtonCustom connect_wallet bordered-green"
          id="wallet-dropdown"
          disabled={!selectedNetwork?.length}
        >
          Connect Wallet
        </Dropdown.Toggle>
      )}
      <Dropdown.Menu>
        <div className="menu_header">
          <h3>Connect Wallet</h3>
          <button onClick={handleClose} className="modal_close_btn">
            <CloseIcon />
          </button>
        </div>
        <div className="menu_body">
          <p>
            Choose a wallet you want to connect. There are several wallet
            providers.
          </p>
          <ul>
            {selectedNetwork === "Solana" && (
              <li>
                <button onClick={() => handleConnect(0)}>
                  <img src={metamaskIcon} alt="" />
                  Phantom
                </button>
              </li>
            )}
            {(selectedNetwork === "BSC" || selectedNetwork === "Ethereum") && (
              <li>
                <button onClick={() => handleConnect(0)}>
                  <img src={metamaskIcon} alt="" />
                  MetaMask
                </button>
                <br />
                <button onClick={() => handleConnect(1)}>
                  <img src={walletConnectIcon} alt="" />
                  trustwallet{" "}
                </button>
              </li>
            )}
          </ul>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default WalletDropdown;
