import React, { useEffect, useState } from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import {
  CloseIcon,
  TransactionDoneIcon,
} from "../../../../Assets/Images/Icons/SvgIcons";
import "./TransactionDone.scss";
import { TRANSACTION_BASE_URL } from "../../../../constants";
import { useNetwork, useSwitchNetwork } from "wagmi";
import { useSocket } from "../../../../context/socket/SocketProvider";

interface TransactionDetails {
  DepositReceived: boolean;
  validatorSigning: boolean;
  transactionInitiated: boolean;
  executed: boolean;
}

interface TransactionDoneProps {
  show: boolean;
  handleClose: (value: boolean) => void;
  transaction?: { hash: string };
  approval: any;
}

const TransactionDone: React.FC<TransactionDoneProps> = ({
  show,
  handleClose,
  transaction,
  approval,
}) => {
  const [done, setDone] = useState<number>(0);
  const [txnExplorer, setTxnExplorer] = useState<number>(0);
  const [transactionDetails, setTransactionDetails] =
    useState<TransactionDetails | null>(null);
  const socket: any = useSocket();

  const hash = transaction?.hash || "";
  const { chain: CONNECTED_CHAIN_ID }: any = useNetwork();
  console.log("done", done);
  useEffect(() => {
    switch (CONNECTED_CHAIN_ID?.id) {
      case 97:
        setTxnExplorer(TRANSACTION_BASE_URL.bsc);
        break;
      case 11155111:
        setTxnExplorer(TRANSACTION_BASE_URL.eth);
        break;
      default:
        break;
    }
  }, [CONNECTED_CHAIN_ID]);
  const handleTransaction = () => {
    setDone(0);
    setTxnExplorer(0);
    setTransactionDetails(null);
  };
  const handleCloseModal = () => {
    handleTransaction();
    handleClose(false);
  };
  useEffect(() => {
    const hashHandler = (data: any) => {
      setTransactionDetails(data);
      console.log(`Received event ${hash}`, data);
    };
    socket.on(hash, hashHandler);
    return () => {
      socket.off(hash, hashHandler);
    };
  }, [hash, socket]);
  useEffect(() => {
    if (transactionDetails) {
      let progress = 0; // Initialize progress
      if (transactionDetails.DepositReceived) {
        progress = 1; // 25% complete
      }
      if (transactionDetails.validatorSigning) {
        progress = 2; // 50% complete
      }
      if (transactionDetails.transactionInitiated) {
        progress = 3; // 75% complete
      }
      if (transactionDetails.executed) {
        progress = 4; // 100% complete
      }
      setDone(progress);
    }
  }, [transactionDetails]);

  return (
    <Modal
      centered
      className="transModal"
      show={show}
      onHide={() => {
        setDone(0);
        handleTransaction();
        handleClose(false);
      }}
    >
      <button
        onClick={() => {
          setDone(0);
          handleClose(false);
        }}
        className="modal_close_btn"
      >
        <CloseIcon />
      </button>
      <Modal.Body>
        {approval ? (
          <>
            {approval && <TransactionDoneIcon />}
            <h3>Transaction Status</h3>
            <br />
            <p>
              <strong>Transaction Approved</strong>
            </p>
            {approval && (
              <a
                href={`${txnExplorer}/${approval.hash}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View on explorer
              </a>
            )}
          </>
        ) : (
          <>
            {done === 4 && <TransactionDoneIcon />}
            {done !== 4 ? (
              <h3>Pending Transaction</h3>
            ) : (
              <h3>
                {transaction
                  ? "Transaction Complete"
                  : "Transaction Processing"}
              </h3>
            )}
            <p>
              Transaction Status <br />
              {done === 0 && <span>Transaction Pending</span>}
              {done === 1 && <span>Deposit Received</span>}
              {done === 2 && <span>Validator Signing</span>}
              {done === 3 && <span>Transaction Initiated</span>}
              {done === 4 && <span>Transaction Executed</span>}
            </p>
            {transaction && (
              <a
                href={`${txnExplorer}/${transaction.hash}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View on explorer
              </a>
            )}
            <ProgressBar animated max={4} now={done} />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default TransactionDone;
